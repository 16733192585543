import {
  TAuthConverter,
  TCreateConverter,
  TFetchConverter,
} from 'data/types/converter.types';
import {
  TAgeGroupOptionsByLanguageUI,
  TClassDB,
  TClassUI,
  TWorkshopChildcareRequirementsDB,
  TWorkshopChildcareRequirementsUI,
  TWorkshopDB,
  TClassesByAgeGroupUI,
  TClassesByAgeGroupDB,
  TKitShippingAddressEditUI,
  TKitShippingAddressEditDB,
  TSubscribeDataUI,
  TSubscribeDataDB,
  TWorkshopRegisterDataDB,
  TWorkshopRegisterDataUI,
  TWorkshopUI,
  TWorkshopDetailUI,
  TWorkshopDetailDB,
  TClassesUi,
  TClassesDB,
  TSessionsUi,
  TSessionsDB,
  TLanguagesStatusUi,
  TLanguageStatusItemUi,
  TPartInfoUi,
  TPartInfoDB,
  TWorkshopRegisterResponseUI,
  TWorkshopRegisterResponseDB,
  TRegistrationFormDataDB,
  TRegistrationFormDataUI,
  TClassesGroupedByAgeUI,
  TLocationCoordinatesUI,
  TLocationCoordinatesDB,
  EWorkshopAgeGroup,
  EWorkshopPartType,
  TWorkshopEventsUI,
  TWorkshopEventsDB,
  EWorkshopType,
  TLightBoxUI,
  TLightBoxDB,
  TPartsUI,
  TPartsDB,
  TWorkshopMultipleRegisterDataUI,
  TWorkshopMultipleRegisterDataDB,
  TWorkshopMultipleRegisterResponseUI,
  TWorkshopMultipleRegisterResponseDB,
} from 'data/types/workshops.types';
import { ELanguage } from 'data/types/generic.types';
import {
  dateConverter,
  dateFormatWithWeekdayAndTime,
  dateFormatWithYearWeekdayAndTime,
  dateTimeFormatUI,
  dateTimeWithoutSecUI,
  groupAdjacentAges,
  separateDateAndTime,
} from 'helpers/utils';
import {
  addressConvertor,
  i18nLanguageConverter,
  phoneNumberConverter,
  yesNoOptionConverter,
} from './general.converters';

export const workshopChildcareRequirementsConverter: TCreateConverter<
  TWorkshopChildcareRequirementsUI,
  TWorkshopChildcareRequirementsDB
> = {
  toDb: data => {
    return {
      ageGroup: data.name,
      childCount: data.value,
    };
  },
};

export const registerWorkshopConverter: TAuthConverter<
  TWorkshopRegisterDataUI,
  TWorkshopRegisterDataDB,
  TWorkshopRegisterResponseUI,
  TWorkshopRegisterResponseDB
> = {
  toDb: data => {
    const payload: TWorkshopRegisterDataDB = {
      hasReadyBinder: yesNoOptionConverter.toDb(data?.hasReadyBinder),
      workshopId: data.workshopId,
      workshopClassId: data.workshopClassId,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: phoneNumberConverter.toDb(data.phone) || '',
      childNames: (data.childNames || [])
        .map(child => child.name)
        .filter(item => item),
      childcareRequirements: (data.childcareRequirements || []).reduce(
        (acc: TWorkshopChildcareRequirementsDB[], curr) =>
          curr.checked
            ? [...acc, workshopChildcareRequirementsConverter.toDb(curr)]
            : acc,
        [],
      ),
      recaptchaResponse: data.recaptchaResponse,
      userLanguage:
        data.userLanguage && i18nLanguageConverter.toDb(data.userLanguage),
      materialsLanguage: data.materialsLanguage,
      allowedTakePicture: yesNoOptionConverter.toDb(data.allowedTakePicture),
      acceptedTerms: data.acceptedTerms,
    };

    if (data.customQuestionAnswer) {
      payload.customQuestionAnswer = data.customQuestionAnswer;
    }

    return payload;
  },
  fromDb: data => {
    return {
      status: data.status,
      registrantId: data.registrantId,
    };
  },
};

export const registerMultipleWorkshopConverter: TAuthConverter<
  TWorkshopMultipleRegisterDataUI,
  TWorkshopMultipleRegisterDataDB,
  TWorkshopMultipleRegisterResponseUI,
  TWorkshopMultipleRegisterResponseDB
> = {
  toDb: data => {
    return {
      registrationDetails: data.registrationDetails.map(item =>
        registerWorkshopConverter.toDb(item),
      ),
    };
  },
  fromDb: data => {
    return data.map(item => registerWorkshopConverter.fromDb(item));
  },
};

export const partsConverter: TFetchConverter<TPartsUI, TPartsDB> = {
  fromDb: data => {
    return {
      partType: data.partType,
      date: separateDateAndTime(data.dateTime),
      dateTimeString:
        data.dateTime && dateConverter(data.dateTime, dateTimeFormatUI),
      isPassed: data.isPassed,
    };
  },
};
export const workshopClassConverter: TFetchConverter<TClassUI, TClassDB> = {
  fromDb: data => {
    return {
      classId: data.workshopClassId,
      date:
        (data.sessions[0].dateTime &&
          dateConverter(data.sessions[0].dateTime, dateTimeFormatUI)) ||
        '',
      ageGroup: data.ageGroup,
      language: data.language,
      sessions: data.sessions?.map(item => partsConverter.fromDb(item)) || [],
      isAllSessionsPassed: data.sessions?.every(item => item.isPassed),
    };
  },
};
export const classesByAgeGroupConverter: TFetchConverter<
  TClassesByAgeGroupUI[],
  TClassesByAgeGroupDB
> = {
  fromDb: data => {
    //  groups order is used to sort age groupes in UI, add new item in correct place
    const groups = [
      EWorkshopAgeGroup.ZERO_TO_ONE,
      EWorkshopAgeGroup.ONE_TO_TWO,
      EWorkshopAgeGroup.TWO_TO_THREE,
      EWorkshopAgeGroup.TWO_TO_FIVE,
      EWorkshopAgeGroup.THREE_TO_FOUR,
      EWorkshopAgeGroup.THREE_TO_FIVE,
      EWorkshopAgeGroup.FOUR_TO_FIVE,
    ];
    const existingGroups = groups.filter(key => data[key]);
    const objectKeyValues: TClassesByAgeGroupUI[] = existingGroups.map(key => {
      const convertedKey = key as EWorkshopAgeGroup;
      const convertedValue: TClassUI = workshopClassConverter.fromDb(data[key]);
      return [convertedKey, convertedValue] as TClassesByAgeGroupUI;
    });
    return objectKeyValues;
  },
};

const getLanguageOptions = (
  englishClassesLength: number,
  spanishClassesLength: number,
) => {
  const classes = [];
  if (englishClassesLength) {
    classes.push({
      value: ELanguage.ENGLISH,
      label: ELanguage.ENGLISH,
    });
  }
  if (spanishClassesLength) {
    classes.push({
      value: ELanguage.SPANISH,
      label: ELanguage.SPANISH,
    });
  }
  return classes;
};
const getAgeGroupOptionsByLanguage = (
  englishClasses: TClassesByAgeGroupDB | null,
  spanishClasses: TClassesByAgeGroupDB | null,
) => {
  const ageGroupsByLanguage: TAgeGroupOptionsByLanguageUI = {
    [ELanguage.SPANISH]: [],
    [ELanguage.ENGLISH]: [],
  };
  if (Object.keys(englishClasses || {}).length) {
    ageGroupsByLanguage[ELanguage.ENGLISH] = Object.keys(
      englishClasses || {},
    ).map(item => {
      const convertedKey = item as EWorkshopAgeGroup;
      return { value: convertedKey, label: convertedKey };
    });
  }
  if (Object.keys(spanishClasses || {}).length) {
    ageGroupsByLanguage[ELanguage.SPANISH] = Object.keys(
      spanishClasses || {},
    ).map(item => {
      const convertedKey = item as EWorkshopAgeGroup;
      return { value: convertedKey, label: convertedKey };
    });
  }
  return ageGroupsByLanguage;
};

export const locationCoordinatesConverter: TFetchConverter<
  TLocationCoordinatesUI,
  TLocationCoordinatesDB
> = {
  fromDb: data => {
    return {
      latitude: data.latitude,
      longitude: data.longitude,
    };
  },
};

export const sessionsConverter: TFetchConverter<TSessionsUi, TSessionsDB> = {
  fromDb: data => {
    return {
      dateTime:
        data.dateTime &&
        dateConverter(data.dateTime, dateFormatWithYearWeekdayAndTime),
      isPassed: data.isPassed,
      partType: data.partType,
    };
  },
};

export const classConverter: TFetchConverter<TClassesUi, TClassesDB> = {
  fromDb: data => {
    return {
      ageGroup: data.ageGroup,
      language: data.language,
      workshopClassId: data.workshopClassId,
      sessions: data.sessions.map(item => sessionsConverter.fromDb(item)),
    };
  },
};

export const sortByLanguageConverter: TFetchConverter<
  TClassesUi[],
  TClassesDB[]
> = {
  fromDb: data => {
    const englishClasses: TClassesUi[] = [];
    const spanishClasses: TClassesUi[] = [];

    data.forEach(item => {
      if (item.language === ELanguage.ENGLISH) {
        englishClasses.push(classConverter.fromDb(item) || []);
      } else if (item.language === ELanguage.SPANISH) {
        spanishClasses.push(classConverter.fromDb(item) || []);
      }
    });

    return [...englishClasses, ...spanishClasses].filter(
      item => Object.keys(item).length > 0,
    );
  },
};

export const classesGroupedByAgeConverter: TFetchConverter<
  TClassesGroupedByAgeUI[],
  TClassesDB[]
> = {
  fromDb: data => {
    const ageGroups = [
      EWorkshopAgeGroup.ZERO_TO_ONE,
      EWorkshopAgeGroup.ONE_TO_TWO,
      EWorkshopAgeGroup.TWO_TO_THREE,
      EWorkshopAgeGroup.TWO_TO_FIVE,
      EWorkshopAgeGroup.THREE_TO_FOUR,
      EWorkshopAgeGroup.FOUR_TO_FIVE,
      EWorkshopAgeGroup.THREE_TO_FIVE,
    ];

    return ageGroups
      .map(ageGroup => {
        const classesForAgeGroup = data
          .filter(item => item.ageGroup === ageGroup)
          .map(item => item);

        return {
          ...(classesForAgeGroup.length && {
            ageGroup,
            classes: sortByLanguageConverter.fromDb(classesForAgeGroup),
          }),
        };
      })
      .filter(item => Object.keys(item).length > 0);
  },
};

export const workshopConverter: TFetchConverter<
  TWorkshopDetailUI,
  TWorkshopDetailDB
> = {
  fromDb: data => {
    const isAllClassesFinished = data.classes.every(item =>
      item.sessions.every(session => session.isPassed),
    );
    return {
      childcareProvided: data.childcareProvided,
      classes: classesGroupedByAgeConverter.fromDb(data.classes),
      creationDate: data.creationDate,
      foodProvided: data.foodProvided,
      hostId: data.hostId,
      hostName: data.hostName,
      locationAddress: data.locationAddress,
      locationCoordinates:
        data.locationCoordinates &&
        locationCoordinatesConverter.fromDb(data.locationCoordinates),
      locationAddressId: data.locationAddressId,
      locationName: data.locationName,
      workshopCategory: data.workshopCategory,
      workshopDescription: data.workshopDescription,
      workshopId: data.workshopId,
      workshopPhoto: data.workshopPhoto,
      workshopType: data.workshopType,
      isAllClassesFinished,
      attendanceType: data.attendanceType,
    };
  },
};

export const languageStatusItemConverter: TFetchConverter<
  TLanguagesStatusUi,
  TLanguageStatusItemUi
> = {
  fromDb: data => {
    return {
      language: data.language,
      isPassed: data.sessions.every(item => item.isPassed),
    };
  },
};

export const partsInfoConverter: TFetchConverter<TPartInfoUi, TPartInfoDB> = {
  fromDb: data => {
    const partDatesGrouped = Array.from(
      data.parts.map(item => item.dateTime),
    ).flatMap(innerArray => innerArray);
    // Parse the date strings and format them without seconds
    const formattedDates = partDatesGrouped.map(dateString =>
      dateConverter(dateString, dateTimeWithoutSecUI),
    );
    // Check if all formatted dates are the same
    const allDatesAreSame = formattedDates.every(
      date => date === formattedDates[0],
    );
    return {
      partType: data.partType,
      isPassed: data.parts.every(item => item.isPassed),
      dateTime: allDatesAreSame
        ? dateConverter(partDatesGrouped[0], dateFormatWithWeekdayAndTime)
        : null,
    };
  },
};

export const languageStatusConverter: TFetchConverter<
  TLanguagesStatusUi[],
  TClassesUi[]
> = {
  fromDb: data => {
    const languagesArr: TLanguagesStatusUi[] = [];

    const languageData = (sessions: TSessionsUi[], language: ELanguage) => {
      if (sessions.length) {
        const convertedPart = languageStatusItemConverter.fromDb({
          sessions,
          language,
        });
        if (!convertedPart.isPassed) {
          languagesArr.push(convertedPart);
        }
      }
    };

    const englishSessions = data
      .filter(item => item.language === ELanguage.ENGLISH)
      .flatMap(item => item.sessions);
    const spanishSessions = data
      .filter(item => item.language === ELanguage.SPANISH)
      .flatMap(item => item.sessions);

    languageData(englishSessions, ELanguage.ENGLISH);
    languageData(spanishSessions, ELanguage.SPANISH);
    return languagesArr;
  },
};

export const sessionsGroupedConverter: TFetchConverter<
  TPartInfoUi[],
  TWorkshopEventsDB
> = {
  fromDb: data => {
    const partsArr: TPartInfoUi[] = [];

    const partTypes = [
      EWorkshopPartType.PART_ONE,
      EWorkshopPartType.PART_TWO,
      EWorkshopPartType.PART_THREE,
      EWorkshopPartType.STANDALONE,
    ];

    partTypes.forEach(partType => {
      const partArray = data.classes
        .flatMap(item => item.sessions)
        .filter(item => item.partType === partType)
        .flatMap(innerArray => innerArray);

      if (partArray.length) {
        const convertedPart = partsInfoConverter.fromDb({
          partType,
          parts: partArray,
        });
        partsArr.push(convertedPart);
      }
    });
    return partsArr;
  },
};

export const workshopsEventsListConverter: TFetchConverter<
  TWorkshopEventsUI[],
  TWorkshopEventsDB[]
> = {
  fromDb: workshops => {
    return (
      workshops?.map(data => {
        // Group the age groups
        const workshopAgeGroups = Array.from(
          new Set(data.classes.map(item => item.ageGroup)),
        );
        // Grouped all classes in one array
        const workshopClassesGrouped = Array.from(
          data.classes.map(item => item),
        )
          .flatMap(innerArray => innerArray)
          .map(item => classConverter.fromDb(item));
        return {
          classes: (data.classes || []).map(item =>
            classConverter.fromDb(item),
          ),
          sessionsGrouped: sessionsGroupedConverter.fromDb(data),
          ageGroups: groupAdjacentAges(workshopAgeGroups),
          languagesStatus: languageStatusConverter.fromDb(
            workshopClassesGrouped,
          ),
          childcareProvided: data.childcareProvided,
          creationDate: data.creationDate,
          foodProvided: data.foodProvided,
          locationAddress: data.locationAddress,
          locationName: data.locationName,
          workshopCategory: data.workshopCategory,
          workshopId: data.workshopId,
          workshopPhoto: data.workshopPhoto,
          workshopType: data.workshopType,
          attendanceType: data.attendanceType,
        };
      }) || []
    );
  },
};

export const workshopsListConverter: TFetchConverter<TWorkshopUI, TWorkshopDB> =
  {
    fromDb: data => {
      return {
        hostName: data.hostName,
        workshopEvents: workshopsEventsListConverter.fromDb(
          data.workshopEvents,
        ),
      };
    },
  };

export const kitOrderingShippingAddressConverter: TCreateConverter<
  TKitShippingAddressEditUI,
  TKitShippingAddressEditDB
> = {
  toDb: data => ({
    parentOrderId: data.parentOrderId,
    address: addressConvertor.toDb(data.address),
  }),
};

export const notifyMeConverter: TCreateConverter<
  TSubscribeDataUI,
  TSubscribeDataDB
> = {
  toDb: data => {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: phoneNumberConverter.toDb(data.phone) || '',
      recaptchaResponse: data.recaptchaResponse,
      cities: data.cities,
      ageGroups: (data.ageGroups || []).map(ageGroup => ageGroup),
      languages: (data.languages || []).map(language => language),
    };
  },
};

export const registrationFormDataConverter: TFetchConverter<
  TRegistrationFormDataUI,
  TRegistrationFormDataDB
> = {
  fromDb: data => {
    const spanishClasses = data.spanishClasses
      ? classesByAgeGroupConverter.fromDb(data.spanishClasses)
      : [];
    const englishClasses = data.englishClasses
      ? classesByAgeGroupConverter.fromDb(data.englishClasses)
      : [];

    const isCCP = [
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_1,
      EWorkshopType.CHILD_CARE_AND_PRESCHOOL_PROGRAMS_PART_2,
      EWorkshopType.LEARNING_CHILD_CARE_AND_PRESCHOOL_PROGRAMS,
    ].includes(data.workshopType);

    return {
      childcareProvided: data.childcareProvided,
      foodProvided: data.foodProvided,
      hostId: data.hostId,
      hostName: data.hostName,
      workshopId: data.workshopId,
      workshopType: data.workshopType,
      workshopCategory: data.workshopCategory,
      spanishClasses,
      englishClasses,
      languageOptions: getLanguageOptions(
        Object.keys(data.englishClasses || {}).length,
        Object.keys(data.spanishClasses || {}).length,
      ),
      ageGroupOptions: getAgeGroupOptionsByLanguage(
        data.englishClasses,
        data.spanishClasses,
      ),
      parts: data.parts?.map(item => partsConverter.fromDb(item)) || [],
      customQuestion: data.customQuestion,
      isCCP,
      locationAddress: data.locationAddress,
    };
  },
};

export const lightBoxConverter: TFetchConverter<TLightBoxUI, TLightBoxDB> = {
  fromDb: data => {
    return {
      id: data.id,
      firstSection: data.firstSection,
      secondSection: data.secondSection,
      picture: data.picture,
      published: data.published,
    };
  },
};
